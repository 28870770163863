import React from 'react'
import { logout } from "../../services/Auth"

const Navbar = () => {

    function handleLogout() {
        logout().then(r => {
          window.location.reload();
        }).catch(err => {
          console.log(err);
        })
      }

  return (
    <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <div className="container">
                <a className="navbar-brand mt-2" href="/"><h3>ScoveAdmin</h3></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* Sign out button to left */}
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/" onClick={() => handleLogout()} ><i className="fa-solid fa-sign-out"></i> Sign Out</a>
                        </li>
                    </ul>
                  </div>
            </div>
        </nav>
    </>
  )
}

export default Navbar