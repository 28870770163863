import React from 'react'
import AdminMenuCard from '../../components/AdminMenuCard/AdminMenuCard'

const Home = () => {
  return (
    <>
        <div className="container">
            {/* Search Bar */}
            <div className="row mt-3 px-5">
                <div className="col-md-12">
                    <div className="input-group mb-3 shadow">
                        <input type="text" className="form-control border-0" placeholder="Search in Settings" aria-label="Search" aria-describedby="button-addon2" />
                        <button className="btn btn-outline-secondary border-0 " type="button" id="button-addon2"><i class="fa-solid fa-magnifying-glass"></i></button>
                    </div>
                </div>
            </div>
            {/* Menu */}
            <div className="row">
                <h6 className='mt-3'>User Actions</h6>
                <hr />
                <AdminMenuCard
                    icon="fa-solid fa-user"
                    color="success"
                    menuName="Users"
                    menuDesc="Manage users"
                    link="/users"
                />
                <h6 className='mt-3'>Product Actions</h6>
                <hr />
                <AdminMenuCard
                    icon="fa-solid fa-calendar-days "
                    color="primary"
                    menuName="Appointments"
                    menuDesc="Check appointments"
                    link="/appointments"
                />
                <AdminMenuCard
                    icon="fa-solid fa-microscope "
                    color="dark"
                    menuName="Analysis"
                    menuDesc="Check analysis"
                    link="/analysis"
                />
                <h6 className='mt-3'>Cloud Actions</h6>
                <hr />
                <AdminMenuCard
                    icon="fa-solid fa-folder-open"
                    color="warning"
                    menuName="Drive"
                    menuDesc="Manage drive"
                    link="/drive"
                />
                <AdminMenuCard
                    icon="fa-solid fa-file-video"
                    color="info"
                    menuName="Videos"
                    menuDesc="Show uploaded videos"
                    link="/videos"
                />
                <h6 className='mt-3'>System Actions</h6>
                <hr />
                <AdminMenuCard
                    icon="fa-solid fa-cog"
                    color="danger"
                    menuName="Settings"
                    menuDesc="Manage your settings"
                    link="/settings"
                />
                <AdminMenuCard
                    icon="fa-solid fa-server"
                    color="secondary"
                    menuName="Server"
                    menuDesc="Check server health"
                    link="/server"
                />
            </div>
        </div>
    </>
  )
}

export default Home