import React from 'react'

const Videos = () => {
  return (
    <>
        <div className="container">
            {/* Analysis Table */}
            <div className="row mt-3 px-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="card border border-0 shadow my-3">
                            <div className="card-body">
                                <i class="fa-solid fa-file-video fs-2"></i>
                                <span className="fw-bold fs-2 ms-2"> Videos </span><br />
                                <span className='text-muted'>List of videos submitted for analysis</span>
                            </div>
                        </div>
                    </div>
                    <div className="card border border-0 shadow card-max-wrap" id="style-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <select
                                        class="form-select border border-0 shadow  rounded-0"
                                        aria-label="Default select example"
                                    >
                                        <option selected>Default Sort</option>
                                        <option value="1">Most Recent Date First</option>
                                        <option value="2">Most Distant Date First</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <div class="input-group shadow mb-3">
                                        <input
                                            type="text"
                                            class="form-control border border-0 rounded-0"
                                            placeholder="Search Videos"
                                            aria-describedby="button-addon2"
                                        />
                                        <button
                                            class="btn btn-outline-dark border border-0 rounded-0"
                                            type="button"
                                            id="button-addon2"
                                        >
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Videos