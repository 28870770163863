import Cookies from "js-cookie";


async function login(email, password) {
  return await fetch("https://scove-api.herokuapp.com/api/auth/login/admin", {
    // Cors options
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
  .then((response) => response.json())
  .then(data => {
      console.log(data);
      Cookies.set("login", true, { expires: 7, path: "/" });
      Cookies.set("id", data.admin._id, { expires: 7, path: "/" });
      Cookies.set("username", data.admin.name, { expires: 7, path: "/" });
      Cookies.set("email", data.admin.email, { expires: 7, path: "/" });
      Cookies.set("title", data.admin.title, {expires: 7, path: "/" })
      Cookies.set("settings", data.admin.settings, {expires: 7, path: "/" })
      Cookies.set("role", "ADMIN", { expires: 7, path: "/" });
      window.location.href = "/";
      return true;
    }).catch((error) => {
      return console.log(error);
    });
}

// async function register(userName, email, password, title, institution, phone) {
//   return await fetch("http://api.scove-systems.com/users", {
//   // return await fetch("https://scove-cloud-api.herokuapp.com/api/v1/auth/register", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json;charset=utf-8",
//     },
//     body: JSON.stringify({
//       user_name: userName,
//       user_email: email,
//       user_password: password,
//     }),
//   })
//     .then((response) => {
//       console.log(response.status);

//       if (response.status === 201) {
//         return response.json();
//       } else return false;
//     })
//     .then((response) => {
//       if (response === false) {
//         return false;
//       }
//       console.log(response);
//       console.log(response._id);
//       Cookies.set("id", response._id, { expires: 7, path: "/" });
//       Cookies.set("username", response.username, { expires: 7, path: "/" });
//       Cookies.set("email", response.email, { expires: 7, path: "/" });
//       Cookies.set("role", response.isAdmin, { expires: 7, path: "/" });
//       return true;
//     });
// }

async function logout() {
  Cookies.remove("login", { path: "/" });
  Cookies.remove("id", { path: "/" });
  Cookies.remove("username", { path: "/" });
  Cookies.remove("email", { path: "/" });
  Cookies.remove("role", { path: "/" });
}

export { login, logout };