import React from 'react'

const AppointmentItemCard = (props) => {

    const [loading, setLoading] = React.useState(false)

    const handleAccept = () => {
        setLoading(true)
        const res = fetch("https://scove-api.herokuapp.com/api/appointments/" + props.id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                status: "FINISHED"
            })
        }).then((res) => {
            if (res.status === 200) {
                window.location.reload()
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

  return (
    <>
        <div className="card border border-0 shadow rounded mb-1">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-3 text-center fw-bold">{props.date}</div>
                    <div className="col-lg-3 text-center text-truncate">{props.user}</div>
                    {props.status === "PENDING" ? <div className="col-lg-3 text-center"><i class="fa-solid fa-sync fa-spin fs-3 text-warning" ></i></div> : ""}
                    {props.status === "PROCESSING" ? <div className="col-lg-3 text-center"><i class="fa-solid fa-gear fa-spin fs-3 text-primary"></i></div> : ""}
                    {props.status === "CANCELED" ? <div className="col-lg-3 text-center"><i class="fa-solid fa-circle-xmark fs-3 text-danger"></i></div> : ""}
                    {props.status === "FINISHED" ? <div className="col-lg-3 text-center"><i class="fa-solid fa-circle-check fs-3 text-success"></i></div> : ""}
                    

                    {
                        loading ? (
                            <div className="col-lg-3 text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            props.status === "PENDING" ? (
                                <div className="col-lg-3 text-center"> 
                                <button type='button' className='btn btn-outline-success border-0 shadow w-100' onClick={handleAccept}>
                                    <i class="fa-solid fa-circle-check"></i> Onayla
                                </button>
                            </div> 
                            ) : (
                                <div className="col-lg-3 text-center">
                                    <button type='button' className='btn btn-outline-secondary border-0 shadow w-100' disabled>
                                        <i class="fa-solid fa-circle-check"></i> Onaylandı
                                    </button>
                                </div>
                            )
                        )
                    }

                    {/* {
                        props.status === "PENDING" ? 
                            <div className="col-lg-3 text-center"> 
                                <button type='button' className='btn btn-outline-success border-0 shadow w-100' onClick={handleAccept}>
                                    <i class="fa-solid fa-circle-check"></i> Onayla
                                </button>
                            </div> 
                        : ""
                    }
                    {
                        props.status === "FINISHED" ? 
                            <div className="col-lg-3 text-center">
                                <button className='btn btn-outline-warning border-0 shadow w-100'>
                                    <i class="fa-solid fa-circle-xmark fs-3 text-danger"></i> Beklet
                                </button>
                            </div> 
                        : ""
                    } */}
                </div>
            </div>
        </div>
    </>
  )
}

export default AppointmentItemCard