import React from 'react'

const UserListItem = (props) => {
  return (
    <>
      <div className="card border border-0 shadow rounded mb-1">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 text-center fw-bold">{props.id}</div>
            <div className="col-lg-3 text-center text-truncate">{props.name}</div>
            <div className="col-lg-2 text-center"><button className="btn btn-primary w-100"><i class="fa-solid fa-circle-info"></i> Details</button></div>
            <div className="col-lg-2 text-center"><button className="btn btn-warning text-white w-100"><i class="fa-solid fa-pen-to-square"></i> Edit</button></div>
            <div className="col-lg-2 text-center"><button className="btn btn-danger w-100"><i class="fa-solid fa-trash"></i> Delete</button></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserListItem