import React from 'react'

const AnalysisListItem = (props) => {
    return (
        <>
          <div className="card border border-0 shadow rounded mb-1">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 text-center fw-bold"><a href={"/analysis/"+ props.analysisId}>{props.analysisId}</a></div>
                <div className="col-lg-4 text-center text-truncate">{props.folderName}</div>
                <div className="col-lg-2 text-center">{props.appointmentDate}</div>
                {props.status === "PENDING" ? <div className="col-lg-2 text-center"><i class="fa-solid fa-sync fa-spin fs-3 text-warning" ></i></div> : ""}
                {props.status === "PROCESSING" ? <div className="col-lg-2 text-center"><i class="fa-solid fa-gear fa-spin fs-3 text-primary"></i></div> : ""}
                {props.status === "CANCELED" ? <div className="col-lg-2 text-center"><i class="fa-solid fa-circle-xmark fs-3 text-danger"></i></div> : ""}
                {props.status === "FINISHED" ? <div className="col-lg-2 text-center"><i class="fa-solid fa-circle-check fs-3 text-success"></i></div> : ""}
              </div>
            </div>
          </div>
        </>
      )
}

export default AnalysisListItem