import React, { useState } from "react";
import { login } from "../../services/Auth";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  async function handleLogin() {
    // if return is true, then login was successful
    if (!await login(email, password)){
      alert("Login failed");
    }
  }
  return (
    <>
      <div className="login-wrap">
        <div className="card p-5 border-0 rounded shadow">
          <img
            className="img-fluid bg-primary px-5 py-3 rounded"
            src="http://scove-systems.com/media-placeholder/scove-cloud-light-bg-transparent-8342x2613-1.png"
            alt=""
          />
          <h5 className="mb-1 mt-3 text-center">Admin</h5>
          <hr />
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              aria-label="Password"
              aria-describedby="basic-addon2"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="btn btn-primary  w-100" onClick={() => handleLogin()}>
            <i className="fa-solid fa-right-to-bracket"></i> Log In
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
