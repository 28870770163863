import React from 'react'
import { Link } from 'react-router-dom'

const AdminMenuCard = (props) => {
  return (
    <>
        <div className="col-md-4 px-5 pb-2">
            <div className="card border-0 shadow">
                <div className="card-body text-center">
                    <i className={props.icon + " fs-2 text-" + props.color}></i>
                    <h5 className="card-title">{props.menuName}</h5>
                    <p className="text-muted">{props.menuDesc}</p>
                    <Link to={props.link} className={"btn btn-" + props.color}>Go to {props.menuName}</Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default AdminMenuCard