import React, { useEffect, useState } from 'react'

import AnalysisListItem from '../../components/Analysis/AnalysisListItem'

const Analysis = () => {
    const [analysisList, setAnalysisList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        document.title = "Analysis | Admin"
        // Connect db for getting analysis data with fetch
        setLoading(true)
        const res = fetch("https://scove-api.herokuapp.com/api/analysis", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                return res.json()
            }
        }).then((data) => {
            setAnalysisList(data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

  return (
        <>
            <div className="container">
                {/* Analysis Table */}
                <div className="row mt-3 px-5">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="card border border-0 shadow my-3">
                                <div className="card-body">
                                    <i class="fa-solid fa-microscope fs-2"></i>
                                    <span className="fw-bold fs-2 ms-2">Analysis And Results</span><br />
                                    <span className='text-muted'>Manage Analysis And Results</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border border-0 shadow card-max-wrap" id="style-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <select
                                        class="form-select border border-0 shadow  rounded-0"
                                        aria-label="Default select example"
                                    >
                                        <option selected>Default Sort</option>
                                        <option value="1">Most Recent Date First</option>
                                        <option value="2">Most Distant Date First</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <div class="input-group shadow mb-3">
                                        <input
                                            type="text"
                                            class="form-control border border-0 rounded-0"
                                            placeholder="Search Analysis"
                                            aria-describedby="button-addon2" 
                                        />
                                        <button
                                            class="btn btn-outline-dark border border-0 rounded-0"
                                            type="button"
                                            id="button-addon2"
                                        >
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3"></div>
                                <div className="col-lg-3 ">
                                    {/* Show Only DONE with checkbox */}
                                    <div class="form-check form-switch d-flex align-items-center justify-content-center">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                        <label class="form-check-label ms-2" for="flexSwitchCheckDefault" > Show Only DONE </label>
                                    </div>
                                </div>    
                            </div>
                            <div className="container mt-3">
                                <div className="row">
                                    <div className="card border border-0 shadow rounded mb-1">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-4 text-center fw-bold">Analysis Id</div>
                                                <div className="col-lg-4 text-center fw-bold">Folder Name</div>
                                                <div className="col-lg-2 text-center fw-bold">Appointment Date</div>
                                                <div className="col-lg-2 text-center fw-bold">Status</div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        loading ? (
                                            <div className="card border border-0 shadow rounded mb-1">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12 text-center fw-bold">Loading...</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            analysisList.map((analysis) => {
                                                return (
                                                    <AnalysisListItem
                                                        key={analysis._id}
                                                        analysisId={analysis._id}
                                                        folderName={analysis.filename}
                                                        appointmentDate={analysis.created_at}
                                                        status={analysis.resultStatus} 
                                                    />
                                                )
                                            })
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Analysis