import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from 'axios';

const AnalysisSingle = () => {
    let { id } = useParams();

    const [apiData, setApiData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fileUpload, setFileUpload] = useState(false)

    const [links, setLinks] = useState("");

    const { register, handleSubmit } = useForm();

    const onSubmit = async e => {
        const formData = new FormData();
        formData.append("file", e.file[0]);

        setFileUpload(true)

        try{
            await axios({
                method: 'post',
                url: "https://scove-api.herokuapp.com/api/cloud/upload/result/" + apiData.analysis.foldername,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }).then(res => {
                console.log(res)
                setFileUpload(false);
            }).catch(err => {
                console.log(err)
            })
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetch(`https://scove-api.herokuapp.com/api/analysis/${id}`)
            .then(res => {
                return res.json()
            }).then(data => {
                setApiData(data);
                return data;
            }).then(data => {
                fetch(`https://scove-api.herokuapp.com/api/cloud/link`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        filepath: data.analysis.filepath
                    })
                }).then(res => {
                    return res.json()
                }).then(data => {
                    setLinks(data)
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                })
            }).catch(err => {
                console.log(err)
            })
    }, [id])

    // useEffect(() => {
    //     fetch(`https://scove-api.herokuapp.com/api/cloud/link`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             filepath: apiData.analysis.filepath
    //         })
    //     }).then(res => {
    //         return res.json()
    //     }).then(data => {
    //         setLinks(data)
    //         setLoading(false)
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }, [apiData.analysis.filepath]);


    return (
            <>
              <div className="container">
                  {/* Analysis Table */}
                  <div className="row mt-3 px-5">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="card border border-0 shadow my-3">
                                    <div className="card-body">
                                        <i class="fa-solid fa-microscope fs-2"></i>
                                        <span className="fw-bold fs-2 ms-2">Analysis Id :  {id} </span><br />
                                        <span className='text-muted'>Add Result</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border border-0 shadow card-max-wrap" id="style-3">
                            <div className="card-body">
                                {
                                    loading ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="list-group">
                                                        <div className="list-group-item list-group-item-action border-0 shadow">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">{ apiData.user.name }</h5>
                                                                {/* Write clear date */}
                                                                <small className="text-muted">
                                                                    {apiData.analysis.created_at}
                                                                </small>
                                                            </div>
                                                            <p className="mb-1">Folder Name: { apiData.analysis.foldername }</p>
                                                            {
                                                                links ? (
                                                                    <a href={links} target="_blank" className="btn btn-primary w-100" download>Download</a>
                                                                ) : (
                                                                    <button className="btn btn-primary w-100" disabled>{ apiData.analysis.filepath }</button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Upload File Section */}
                                                <div className="col-lg-6">
                                                    <div className="card border border-0 shadow rounded mb-1">
                                                        <div className="card-body pt-2">
                                                            {
                                                                fileUpload ? (
                                                                    <div className="d-flex justify-content-center">
                                                                        <div className="spinner-border text-primary" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <span className='fs-6 ms-1'>Upload Result File</span>
                                                                        <div className="input-group mt-2">
                                                                            <input 
                                                                                type="file" 
                                                                                className="form-control" 
                                                                                id="inputGroupFile02" 
                                                                                {...register("file")}
                                                                            />
                                                                            <button className="btn btn-secondary" type="submit">Upload</button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mt-2">
                                                    <div className="card border border-0 shadow rounded mb-1">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    {
                                                                        apiData.analysis.status === "FINISHED" ? (
                                                                            // Finished
                                                                            <button type="submit" className="btn btn-success btn-block" disabled>Finished</button>
                                                                        ) : (
                                                                            <button className="btn btn-success w-100" type='button' onClick={() => {
                                                                                setLoading(true)
                                                                                fetch(`https://scove-api.herokuapp.com/api/analysis/${id}/set/FINISHED`, {
                                                                                    method: 'PUT',
                                                                                    headers: {
                                                                                        'Content-Type': 'application/json'
                                                                                    }
                                                                                }).then(res => {
                                                                                    setLoading(false)
                                                                                }).catch(err => {
                                                                                    console.log(err)
                                                                                })  
                                                                            }}>
                                                                                <i className="fa-solid fa-circle-check"></i>
                                                                                {" "} Set Finished
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }
                                {/* Result Data Show Section */}
                            </div>
                        </div>
                  </div>
              </div>
          </>
    )
}

export default AnalysisSingle