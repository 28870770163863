import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

import UserListItem from '../../components/Users/UserListItem'

const Users = () => {

    // user values
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [role, setRole] = useState("")

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch('https://scove-api.herokuapp.com/api/users')
            .then(res => {
                console.log(res)
                return res.json()
            })
            .then(data => {
                setUsers(data)
                setLoading(false)
            })
    }, [])

    const createUser = (e) => {
        e.preventDefault()
        const user = { name, email, password, role }
        console.log(user)

        fetch('https://scove-api.herokuapp.com/api/users', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(user)
        }).then(() => {
            console.log('new user added')
            window.location.reload()
        })
    }
    
    

    return (
    <>
        <div className="container mt-3">
            {/* Users Card */}
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="card border border-0 shadow my-3">
                            <div className="card-body">
                                <i class="fa-solid fa-users-gear fs-2"></i>
                                <span className="fw-bold fs-2 ms-2">Users</span><br />
                                <span className='text-muted'>Manage Users</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card border border-0 shadow card-max-wrap" id="style-3">
                        <div className="card-body">
                        <div className="row">
                            <div className="col-lg-3">
                            <select
                                class="form-select border border-0 shadow  rounded-0"
                                aria-label="Default select example"
                            >
                                <option selected>Default Sort</option>
                                <option value="1">Most Recent Date First</option>
                                <option value="2">Most Distant Date First</option>
                            </select>
                            </div>
                            <div className="col-lg-3">
                            <div class="input-group shadow mb-3">
                                <input
                                    type="text"
                                    class="form-control border border-0 rounded-0"
                                    placeholder="Search User"
                                    aria-describedby="button-addon2" 
                                />
                                <button
                                    class="btn btn-outline-dark border border-0 rounded-0"
                                    type="button"
                                    id="button-addon2"
                                >
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3 "></div>
                            <div className="col-lg-3">
                                <button
                                    className="w-100 btn btn-outline-dark border border-0 shadow fs-5  rounded-0"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                >
                                    <i className="fa-solid fa-user-plus"></i> Add New User
                                </button>
                            </div> 
                        </div>
                        <div className="container mt-3">
                            <div className="row">
                                <div className="card border border-0 shadow rounded mb-1">
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 text-center fw-bold">User Id</div>
                                        <div className="col-lg-3 text-center fw-bold">
                                            User Name
                                        </div>
                                        <div className="col-lg-6 text-center fw-bold">Actions</div>
                                    </div>
                                    </div>
                                </div>
                                {
                                    loading ? (
                                        <div className="text-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        users.map((user) => {
                                            return (
                                                <UserListItem
                                                    id={user._id}
                                                    name={user.name}   
                                                />
                                            )
                                        })
                                    )
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdropLabel">
                                    Create a User
                                    </h5>
                                    <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div class="modal-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label for="exampleInputEmail1" className="form-label">
                                                        User Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        onChange={(e) => {
                                                            setName(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label for="exampleInputEmail1" className="form-label">
                                                        User Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        onChange={(e) => {
                                                            setEmail(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label for="exampleInputEmail1" className="form-label">
                                                        User Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        onChange={(e) => {
                                                            setPassword(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label for="exampleInputEmail1" className="form-label">
                                                        User Role
                                                    </label>
                                                    <select
                                                        class="form-select border border-0 shadow  rounded-0"
                                                        aria-label="Default select example"
                                                        onChange={(e) => {
                                                            setRole(e.target.value)
                                                        }}
                                                    >
                                                        <option selected>Default Sort</option>
                                                        <option value="1">Most Recent Date First</option>
                                                        <option value="2">Most Distant Date First</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        type="button"
                                        class="btn btn-danger"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button 
                                        type="button" 
                                        class="btn btn-success"
                                        onClick={createUser}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Users