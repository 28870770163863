import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";

import LoginLayout from "./pages/Layouts/LoginLayout";
import Layout from "./pages/Layouts/HomeLayout";

import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Users from "./pages/Users/Users";
import Analysis from "./pages/Analysis/Analysis";
import AnalysisSingle from "./pages/Analysis/AnalysisSingle";
import Appointments from "./pages/Appointments/Appointments";
import Videos from "./pages/Videos/Videos";

import NoPage from "./pages/NoPage";


function App() {
  if (!Cookies.get("login") || Cookies.get("role") !== "ADMIN") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginLayout />}>
            <Route index element={<Login />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/analysis" element={<Analysis />} />
          <Route path="/analysis/:id" element={<AnalysisSingle />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
