import React from 'react'

import AppointmentItemCard from '../../components/Appointments/AppointmentItemCard'

const Appointments = () => {

    const [appointments, setAppointments] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        document.title = "Appointments | Admin"
        // Connect db for getting analysis data with fetch
        setLoading(true)
        const res = fetch("https://scove-api.herokuapp.com/api/appointments", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                return res.json()
            }
        }).then((data) => {
            setAppointments(data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

  return (
    <>
        <div className="container">
            {/* Analysis Table */}
            <div className="row mt-3 px-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="card border border-0 shadow my-3">
                            <div className="card-body">
                                <i class="fa-solid fa-calendar-days fs-2"></i>
                                <span className="fw-bold fs-2 ms-2"> Appointments </span><br />
                                <span className='text-muted'>Add appointments and accept appointments request</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border border-0 shadow card-max-wrap" id="style-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-3">
                                <select
                                    class="form-select border border-0 shadow  rounded-0"
                                    aria-label="Default select example"
                                >
                                    <option selected>Default Sort</option>
                                    <option value="1">Most Recent Date First</option>
                                    <option value="2">Most Distant Date First</option>
                                </select>
                            </div>
                            <div className="col-lg-3">
                                <div class="input-group shadow mb-3">
                                    <input
                                        type="text"
                                        class="form-control border border-0 rounded-0"
                                        placeholder="Search Appointments"
                                        aria-describedby="button-addon2"
                                    />
                                    <button
                                        class="btn btn-outline-dark border border-0 rounded-0"
                                        type="button"
                                        id="button-addon2"
                                    >
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                            <div className="col-lg-3 ">
                                {/* Show Only DONE with checkbox */}
                                <div class="form-check form-switch d-flex align-items-center justify-content-center">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    <label class="form-check-label ms-2" for="flexSwitchCheckDefault" > Show Only Accepted </label>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-3">
                            <div className="row">
                                <div className="card border border-0 shadow rounded mb-1">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-3 text-center fw-bold">Appointment Date</div>
                                            <div className="col-lg-3 text-center fw-bold">User</div>
                                            <div className="col-lg-3 text-center fw-bold">Status</div>
                                            <div className="col-lg-3 text-center fw-bold"></div>
                                        </div>
                                    </div>
                                </div>    
                                {
                                    loading ? (
                                        <div className="text-center mt-5">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        appointments ? (
                                            appointments.map((appointment) => {
                                                return (
                                                    <AppointmentItemCard 
                                                        key={appointment._id}
                                                        id={appointment._id}
                                                        date={appointment.date}
                                                        user={appointment.username}
                                                        status={appointment.status}
                                                    />
                                                )
                                            })
                                        ) : (
                                            <div className="text-center mt-5">
                                                <span className="text-muted">No Appointments Found</span>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Appointments